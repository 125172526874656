import { ZoomMtg } from '@zoomus/websdk';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.11.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

const API_KEY = process.env.VUE_APP_ZOOM_API_SDKKEY;
const API_SECRET = '';

const MeetingCfg = (meetingId, meetingPassword, username) => ({
  sdkKey: API_KEY,
  signature: API_SECRET,
  meetingNumber: meetingId,
  userName: username,
  passWord: meetingPassword,
  leaveUrl: 'https://admin.journeyapp.com/meetings',
  role: 1,
});

export default { MeetingCfg, ZoomMtg };
