var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "goback" }, [
      _c(
        "div",
        { on: { click: _vm.endMeeting } },
        [
          _c(
            "icon-container",
            {
              attrs: {
                width: 40,
                height: 18,
                name: "Go Back",
                color: "#fefefe",
              },
            },
            [_c("icon-goback")],
            1
          ),
          _vm._v(" " + _vm._s(_vm.$t("go_back")) + " "),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }