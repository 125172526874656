<template>
  <div class="page">
    <div class="goback">
      <div
        @click="endMeeting"
      >
        <icon-container
          :width="40"
          :height="18"
          name="Go Back"
          color="#fefefe"
        >
          <icon-goback />
        </icon-container> {{ $t('go_back') }}
      </div>
    </div>
  </div>
</template>

<script>

import IconContainer from '@/components/elements/Icon.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import API from '@/services/';
import { API_ZOOM_SIGNATURE } from '@/helpers/config';
import zoomConfig from '../helpers/zoom-config';

export default {
  name: 'Meeting',
  data() {
    return {
      meetingInstance: null,
    };
  },
  props: {
    meetingID: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
    meetingPassword: {
      type: String,
      default: '',
    },
  },

  components: {
    'icon-container': IconContainer,
    'icon-goback': IconGoBack,
  },

  beforeDestroy() {
    document.getElementById('zmmtg-root').style.display = 'none!important';
    this.removeCssFile();
  },

  created() {
    document.getElementById('zmmtg-root').style.display = 'block';
    this.appendCssFile();
  },

  methods: {
    endMeeting() {
      this.meetingInstance.endMeeting({});
      document.getElementById('zmmtg-root').style.display = 'none';
      this.$router.push({ name: 'Meetings' });
      console.log('Meeting ended');
    },
    getSignature(meetingID, roleID = 1) {
      return new Promise((resolve, reject) => {
        API.get(`${API_ZOOM_SIGNATURE}/${meetingID}/${roleID}`)
          .then(response => resolve(response.data.signature))
          .catch((error) => {
            this.$helpers.displayError(error);
            return reject(error);
          });
      });
    },
    appendCssFile() {
      const file = document.createElement('link');
      file.rel = 'stylesheet';
      file.id = 'zoomfirstcss';
      file.href = 'https://source.zoom.us/2.11.0/css/bootstrap.css';
      document.head.appendChild(file);
      const file2 = document.createElement('link');
      file2.rel = 'stylesheet';
      file2.id = 'zoomsecondcss';
      file2.href = 'https://source.zoom.us/2.11.0/css/react-select.css';
      document.head.appendChild(file2);
      console.log('css appended');
    },

    removeCssFile() {
      document.getElementById('zoomfirstcss').disabled = true;
      document.getElementById('zoomsecondcss').disabled = true;
      document.getElementById('zoomfirstcss').remove();
      document.getElementById('zoomsecondcss').remove();
      console.log('css removed');
    },
  },
  async mounted() {
    console.log('meetingID:', this.meetingID, 'meetingPassword: ', this.meetingPassword, 'Username: ', this.username);
    const meetConfig = zoomConfig.MeetingCfg(this.meetingID, this.meetingPassword, this.username);
    const { ZoomMtg } = zoomConfig;
    this.meetingInstance = ZoomMtg;
    // Generate Signature function
    const signature = await this.getSignature(meetConfig.meetingNumber);
    console.log('Signature:', signature);
    // join function
    ZoomMtg.init({
      leaveUrl: meetConfig.leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(`trying to join ${JSON.stringify(success)}`);

        ZoomMtg.join({
          signature,
          meetingNumber: meetConfig.meetingNumber,
          userName: meetConfig.userName,
          sdkKey: meetConfig.sdkKey,
          userEmail: 'emre@journeyapp.com',
          passWord: meetConfig.passWord,
          success: (res) => {
            // eslint-disable-next-line
                      console.log("join meeting success", res);
          },
          error: (res) => {
            // eslint-disable-next-line
                      console.log(res);
          },
        });
      },
      error: (res) => {
        // eslint-disable-next-line
              console.log(res);
      },
    });
  },

};
</script>

<style scoped>
.meeting-app {
    width: auto;
    height: auto;
}
#zmmtg-root {
  display: block;
}

.goback {
  z-index: 999999;
  position: absolute;
  top: 50px;
  left: 50px;
  color: #fefefe;
  width: 200px;
  word-wrap: none;
  cursor: pointer;
}
.goback:hover {
 color: gold;
}
.goback:hover svg.icon {
    fill: gold;
}
.btn-default {
  background-color: transparent!important;
}
</style>
